import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ExternalRpt } from '../_models/reporting/externalRpt.model';
import { AlertifyService } from '../_services/common/alertify.service';
import { ExternalService } from '../_services/external/external.service';
import { StudyWorklistService } from '../_services/worklist/study-worklist.service';

@Component({
  selector: 'app-external-report',
  templateUrl: './external-report.component.html',
  styleUrls: ['./external-report.component.scss']
})
export class ExternalReportComponent implements OnInit {

  externalRptParam : ExternalRpt
  rptVisible: boolean;
  reportViewerUrl: string;
  url: string;
  rptUrlSafe: SafeResourceUrl;
  constructor(private activeRoute: ActivatedRoute,
              private externalService:ExternalService,
              public domSanitizer: DomSanitizer,
              private alertify:AlertifyService) { }

  ngOnInit() {

    this.activeRoute.queryParams.subscribe(a => {
    let externalRptParam = new ExternalRpt();
    externalRptParam.location = a.location
    externalRptParam.hn = a.hn;
    externalRptParam.date = new Date(a.date);

      //alert(location + hn+date);
      this.externalService.getExternalReport(externalRptParam).subscribe(
        data => {
          console.log("accNo : ",data);

          if(data){
            let accNo = data;
            this.rptVisible = true;
            this.reportViewerUrl = environment.reportViewerUrl;
            this.url = this.reportViewerUrl +"ReportViewer.aspx?id=ESPrisonRptViewReport&accNo=" + accNo;
      
            this.rptUrlSafe= this.domSanitizer.bypassSecurityTrustResourceUrl(this.url);
          }
          else{
            this.alertify.error("Url not found");
          }
          
        }
      )
      
    })
  }

}
