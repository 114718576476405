import { Component, OnInit, ViewChild } from '@angular/core';
import { StudyModel } from '../_models/study.model';
import { AlertifyService } from '../_services/common/alertify.service';
import { StudyImportService } from '../_services/queue/study-import.service';

@Component({
  selector: 'app-search-patient',
  templateUrl: './search-patient.component.html',
  styleUrls: ['./search-patient.component.css']
})
export class SearchPatientComponent implements OnInit {

  ssn:string;
  patient: StudyModel;
  queueList: StudyModel[]=[];
  cols: { field: string; header: string; }[];
  selectedColumns: { field: string; header: string; }[];

  constructor(private stydyImportService:StudyImportService,
      private alertifyService:AlertifyService
    ) { }

  ngOnInit() {
    this.initilizeStaticData();

  }
  initilizeStaticData() {
    this.cols = [
      { field: 'Queue', header: 'Queue' },
      { field: 'Status', header: 'Status' }
    ];

    this.selectedColumns=this.cols;
  }

  // @ViewChild('btnTest') btnTest:any;

  
  searchPatient(){

    this.ssn = this.ssn.trim();
    //console.log(ssn);
    //console.log('search',data);

    this.stydyImportService.searchPatientBySSN(this.ssn).subscribe(
      data =>{

         if(data){
        let sortedPatientList = data.filter(x => x.Status == 'S').sort((a,b)=> (a.Queue < b.Queue)? -1:1); 
        console.log('sortedList',sortedPatientList);
        this.patient = sortedPatientList.find(x=>x.SSN==this.ssn);
        if(this.patient){
          this.queueList = sortedPatientList;
        }

        else{
          this.alertifyService.warning("No Patient Found!");
          this.patient = null;
          this.queueList = [];
        }
        
       
        console.log('patientInfo',this.patient);
        
        console.log('studyModeldata:',data);
         }else{

          this.alertifyService.warning("No Patient Found!");
          this.patient = null;
          this.queueList = [];

         }
        
      }
      
    )
    
  }

  statusClass(status) {
    //[ngStyle]="{'color:blue':rowData[col.field]=='Waiting'? 'color: green': rowData[col.field]== 'Completed'?
    //'color: brown': rowData[col.field]== 'New'?'color: blue': rowData[col.field]== 'Uploading'}"

    if (status == 'Q' ) {
      return ['brown']
    }
    else if (status == 'W') {
      return ['blue']
    }

    else if (status == 'C') {
      return ['green']
    }
    else if (status == 'D' || status == 'F') {
      return ['red']
    }

  }

  

}
