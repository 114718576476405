import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientStudyModel } from 'src/app/_models/queue/patient-study.model';
import { AlertifyService } from 'src/app/_services/common/alertify.service';
import { StudyImportService } from 'src/app/_services/queue/study-import.service';

@Component({
  selector: 'app-patient-registration',
  templateUrl: './patient-registration.component.html',
  styleUrls: ['./patient-registration.component.css']
})
export class PatientRegistrationComponent implements OnInit {


  patientModel : PatientStudyModel;
  genders: { value: string; }[];
  selectedGender : any;
  orgId: number;
  busId: number;

  constructor(private studyImportService : StudyImportService,
              private alertify :  AlertifyService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.patientModel = new PatientStudyModel();
    this.selectedGender = "Other";
    this.genders = [
      { value: 'Male' },
      { value: 'Female' },
    ];

    this.route.params.subscribe((params) => {
      if (params['org']) {
        this.orgId = +params['org'];
      }
      if(params['bus']){
        this.busId = +params['bus'];
      }
    });

  }

  registerPatient(){

    if(this.patientModel.HN == null || this.patientModel.HN==""){
      this.alertify.error("HN is Mandetory!!");
      return;
    }

    this.patientModel.DOB = this.calculateDob(this.patientModel.Age);
    this.patientModel.Gender = this.selectedGender;
    this.patientModel.BusId = this.busId;
    this.patientModel.OrgId = this.orgId;

    this.studyImportService.saveNewPatientStudyModel(this.patientModel).subscribe(
      data =>{
        if(data){
          this.alertify.success("Appointment Registered");
        }else{
          this.alertify.success("Appointment Previously Existed");

        }

        this.patientModel = new PatientStudyModel();

      }
    )

  }


  year: number;
  date: number;
  month: number;
  birthDate: string;
  numberNine: number;
  age:number;
  birthDay: string;
  birthMonth: string;
  newBirthDate: Date;

  calculateDob(age : any){

    this.age = age;
    this.year = new Date().getFullYear() - this.age;
    this.month = (new Date().getMonth() + 1);
    this.date = new Date().getDate();
    this.numberNine = 9;
    if(this.date <= this.numberNine){
      this.birthDay = '0'+this.date.toString();
      this.birthDate = this.month + '-' + this.birthDay +'-' + this.year.toString();
    }
    else if(this.month <= this.numberNine){
      this.birthMonth = '0' + this.month.toString();
      this.birthDate = this.birthMonth + '-' +  this.date + '-' + this.year.toString();
    }
    else{
    this.birthDate =  this.month + '-' + this.date + '-' + this.year.toString();
  }


    return new Date(this.birthDate);
    
  }

}
