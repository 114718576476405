export const environment = {
  production: true,
  apiUrl: '/SwiftBackendAPI/api/',
  cloudApiUrl: "http://10.10.10.19/SwiftBackendAPI/api/",
  lunitApiUrl: "http://10.10.10.11:8000/",
  imageUrl: '/SwiftBackendAPI/api/ImageUpload/',
  profileImagerUrl: '/SwiftBackendAPI/api/Documents/',
  selectedApiUrl: '/SwiftBackendAPI/api/',
  isCloud: true,
  isSuperAdmin : false,
  isSiteAdmin:false,
  excelTemplatePath: "../../assets/Documents/Templates/",
  reportViewerUrl:'/ReportsViewer/',
  //appUrl : 'http://203.145.118.7/#/',
  appUrl : 'https://doc.dinsight.health/#/',
  refreashTimeinSec : 300000
};
