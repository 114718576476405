export class PatientStudyModel
{
    public QueueSerial: number;
    public BusId : number;
    public SSN: string;
    public FirstName : string;
    public LastName: string;
    public Gender : string;
    public DOB : Date;
    public OrgId : number;
    public InsertBy : number;
    public IsUpdate : boolean;
    public Age : number;

    public AccessionNo: string;
    public ExamName : string;
    public HN : string;
}