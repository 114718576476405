import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppMainComponent } from '../app.main/app.main.component';

import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/primeng';
import { BreadcrumbService } from '../app.breadcrumb/breadcrumb.service';
import { StudyWorklistService } from 'src/app/_services/worklist/study-worklist.service';
import { SiteService } from 'src/app/_services/site/site.services';
import { SiteInformation } from 'src/app/_models/site-information.model';
import { ActivatedRoute } from '@angular/router';
import { StudyModel } from 'src/app/_models/study.model';

@Component({
    selector: 'app-ai-report',
    templateUrl: 'app.ai-report.component.html'
})
export class AppAiReportComponent implements OnDestroy, OnInit {

    subscription: Subscription;

    items: MenuItem[];
    examResult: string;
    selectedSite: SiteInformation;
    accessionNo : string;
    patientInfo: StudyModel;

    constructor(
        private studyWorklistService: StudyWorklistService,
        private siteService : SiteService,
        private route: ActivatedRoute
        ) {

    }
    ngOnInit(): void {

        this.route.queryParams.subscribe(params => {
            this.accessionNo = params['AccessionNumber'];
            this.getPatientInformationByAccessionNo();
          });

        // this.getSiteInformations(); '20181031DX0024'

        // this.examResult = '';

        this.getSiteInformations();
    }
    getPatientInformationByAccessionNo() {

        this.studyWorklistService.getPatientInformationByAccessionNo(this.accessionNo).subscribe(data => {
            if (data) {
            this.patientInfo = data;
                console.log(data);
            }
        });
    }


    getSiteInformations(){
        this.siteService.getSiteInfos().subscribe(
          data =>{

            this.selectedSite = data[0];
            // this.studyWorklistService.getExamResult(this.accessionNo, this.selectedSite.OrgId).subscribe(data => {
            //     if (data) {
            //     this.examResult = data;
            //     }
            // });

          },
        )
      }




    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
