import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { AlertifyService } from 'src/app/_services/common/alertify.service';
import { StudyWorklistService } from 'src/app/_services/worklist/study-worklist.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dcm-viewer',
  templateUrl: './dcm-viewer.component.html',
  styleUrls: ['./dcm-viewer.component.scss']
})
export class DcmViewerComponent implements OnInit {

  constructor(private route : ActivatedRoute,
    private authService : AuthService,
    private alertify : AlertifyService,
    private studyService:StudyWorklistService) { }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      if(params['orgId'] && params['accNo']){
        let orgId=params['orgId'].toString();
        let accNo=params['accNo'].toString();
        this.gotoDicomViewer(orgId,accNo) ; 
      }else{

      }
    });
  }

  gotoDicomViewer(orgId:string,accNo:string){

    //https://doc.dinsight.health
    this.studyService.getOrderDate(accNo,+orgId).subscribe(date=>{

      let link=`${environment.appUrl.replace("#/","")}envisionviewertest/?url=${environment.appUrl.replace("#/","")}envisionviewertest/dcm/${orgId}/${date}/${accNo}/study.json`
      console.log(link);
      
      this.navigateToDicomViewer(link);
    })
    
  }


   navigateToDicomViewer(redirectLink: string) {
    window.open(redirectLink,"_self");
    }
}
