import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from '../api/api.service';
import { User } from "../../_models/user-models/user";
import { BehaviorSubject, Observable } from 'rxjs';
import { SiteInformation } from 'src/app/_models/site-information.model';
import { UserInfo } from 'src/app/_models/user-models/user-info.model';
import { SiteUserInformation } from 'src/app/_models/site-user-information.model';

@Injectable({
  providedIn: "root"
})
export class SiteService {

  photoUrl = new BehaviorSubject<string>('');
  private apiPATH = "Site/";
  private url: string;

  constructor(private router: Router, private api: ApiService) {}


  getSiteInfos(){
    return this.api.get<SiteInformation[]>(`${this.apiPATH}getSiteInfos`);
  }

  approveSite(siteInfo:SiteInformation){
    return this.api.post<boolean>(`${this.apiPATH}approveSite`, siteInfo);
  }

  denySite(siteInfo:SiteInformation) {
    return this.api.post<boolean>(`${this.apiPATH}denySite`, siteInfo);
  }
  insertSiteInformation(site:SiteInformation) {
    return this.api.post<boolean>(`${this.apiPATH}insertSiteInformation`, site);
  }
  getUniqueSuffix(){
    let response = this.api.get<string>(`${this.apiPATH}getUniqueOrgSuffix`);
    return response;
  }

  getUniquePrefix(orgName){
    let response = this.api.get<string>(`${this.apiPATH}getUniqueOrgPrefix/${orgName}`);
    return response;
  }

  isExistEmail(email: string):Observable<boolean> {
    return this.api.get<boolean>(`${this.apiPATH}isExistingSiteEmail/${email}`);
  }

  getSiteDetail(orgId : number){
    return this.api.get<SiteInformation>(`${this.apiPATH}getSiteDetail/${orgId}`);
  }

  updateSitePacsEndPoint(orgId : number, endPoint:string){
    return this.api.put<SiteInformation>(`${this.apiPATH}updateSitePacsEndPoint/${orgId}/${endPoint}`,SiteInformation);
  }
  updateSiteDetails(site: SiteInformation){
    return this.api.put<SiteInformation>(`${this.apiPATH}updateSiteDetails`,site);
  }
}
