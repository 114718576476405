import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { SiteInformation } from 'src/app/_models/site-information.model';
import { UserService } from 'src/app/_services/user/user.service';
import { trimValidator, nameValidator, numberValidator, emailValidator, phoneNumberValidator } from 'src/app/_helper/validator.helper';
import { Router } from '@angular/router';
import { AlertifyService } from 'src/app/_services/common/alertify.service';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { StudyWorklistService } from 'src/app/_services/worklist/study-worklist.service';
import { SiteService } from 'src/app/_services/site/site.services';
@Component({
  selector: 'app-site-registration',
  templateUrl: './site-registration.component.html',
  styleUrls: ['./site-registration.component.css']
})
export class SiteRegistrationComponent implements OnInit {

  siteForm: FormGroup;
  site = {} as SiteInformation;
  emailExists: boolean = true;
  siteEmailExists: boolean = false;
  showSpinner: boolean = false;
  one = 0;

  searchText$: Observable<string>;
  searchTextSubject$ = new Subject<string>();
  searchSiteTextSubject$ = new Subject<string>();


  constructor(private userService: UserService,
    private router: Router,
    private formBuilder: FormBuilder,
    private alertify: AlertifyService,
    private studyWorklistService : StudyWorklistService,
    private siteService : SiteService) { }

  ngOnInit() {

    this.siteForm = this.formBuilder.group({
      orgName: ['', [Validators.required, nameValidator, trimValidator]],
      aliasName: [''],
      orgPhoneNo: ['', [Validators.required, phoneNumberValidator, trimValidator]],
      orgEmail: ['', [Validators.required, emailValidator, trimValidator]],
      orgPrefix : ['',[Validators.required]],
      orgSuffix : [''],
      address: [''],
      orgWebsite: [''],
      firstName: ['', [Validators.required, nameValidator, trimValidator]],
      lastName: ['', [Validators.required, nameValidator, trimValidator]],
      phoneNo: ['', [Validators.required, phoneNumberValidator, trimValidator]],
      email: ['', [Validators.required, emailValidator, trimValidator]],
      newPassword: ['', [Validators.required, trimValidator]],
      confirmPassword: ['', [
        Validators.required
      ]]
    },
      { validator: this.passwordMatch }
    );

    //this.searchText$=
    this.searchTextSubject$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(searchText => this.userService.isExistEmail(searchText))
    ).subscribe(status => {
      this.emailExists = !status;
    });


    this.searchSiteTextSubject$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(searchText => this.siteService.isExistEmail(searchText))
      ).subscribe(status => {
        this.siteEmailExists = !status;
      });


  }

  insertSiteInformation(site: SiteInformation) {
    this.siteService.insertSiteInformation(site).subscribe(
      data => {
        if (data) {
          this.siteForm.reset();
          this.alertify.success('Site information has been saved successfully');
          this.router.navigate(['/login']);
        }
      });
  }

  genarateUniquePrefix(leavemouse){
      let orgName = this.site.OrgName;
      if(this.site.OrgName==undefined && leavemouse == true){
          return;
      }else if(this.site.OrgName==undefined){
        orgName = null;
      }
    this.siteService.getUniquePrefix(orgName).subscribe(data=>{
      this.site.OrgPrefix = data;
    },error=>{

    });

  }

  genarateUniqueSuffix(){
    this.siteService.getUniqueSuffix().subscribe(data=>{
      this.site.OrgSuffix = data;
    },error=>{

    });
  }

  passwordMatch(g: FormGroup) {
    return g.get("newPassword").value === g.get("confirmPassword").value? null : { mismatch: true };
  }

  checkEmail(event) {
    this.searchTextSubject$.next(this.siteForm.controls['email'].value);
  }


  checkSiteEmail(event) {
    this.searchSiteTextSubject$.next(this.siteForm.controls['orgEmail'].value);
  }


}
