import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { CloudApiService } from '../api/cloudApi.service';
import { StudyModel } from '../../_models/study.model';
import { SiteInformation } from 'src/app/_models/site-information.model';
import { WorklistQueryModel } from 'src/app/_models/worklist-query-model';
import { DicomParams } from 'src/app/_models/dicom/dicom-params.model';
import { BiSearchParams } from 'src/app/_models/bi/BiSearchParams';
import { ShareLink } from 'src/app/_models/share-link.model';

@Injectable({
  providedIn: 'root'
})
export class StudyWorklistService {


  private apiPATH = "PatientOrder/";
  constructor(private api: ApiService, private cloudApi: CloudApiService ) { }

  getPatientInformationByAccessionNo(accessionNo: string) {
    let info = this.api.get<StudyModel>(`${this.apiPATH}patientInfo/${accessionNo}`);

    return info;
  }

  

  createShareLinkByStudy(rowData : StudyModel)
  {
    let response = this.api.post<ShareLink>(`${this.apiPATH}createShareLinkByStudy`, rowData);
    return response;
  }



  resendToPacs(StudyInstanceUId: string) {

    let response = this.api.post<boolean>(`${this.apiPATH}resendToLocalPacs/${StudyInstanceUId}`, null);
    return response;
  }
  resendToRis(StudyInstanceUId: string) {

    let response = this.api.post<boolean>(`${this.apiPATH}resendToRis/${StudyInstanceUId}`, null);
    return response;
  }

  getStudyWorklistByDateRange(fromDate: string, toDate: string, orgId: number, isCloud: boolean) {
    let worklist = this.api.get<StudyModel[]>(`${this.apiPATH}patientOrders/${fromDate}/${toDate}/${orgId}/${isCloud}`);

    return worklist;
  }

  getStudyWorklistBySiteList (queryModel: WorklistQueryModel) {
    let worklist = this.api.post<StudyModel[]>(`${this.apiPATH}patientSiteOrders`, queryModel);

    return worklist;
  }

  getISOLastOrderID (queryModel: WorklistQueryModel) {
    let isoLastOrderID = this.api.post<StudyModel[]>(`${this.apiPATH}getisolastorderid`, queryModel);

    return isoLastOrderID;
  }

  getStudyWorklistBySiteListByUser (queryModel: WorklistQueryModel) {
    let worklist = this.api.post<StudyModel[]>(`${this.apiPATH}GetPatientSiteOrdersByUser`, queryModel);
    return worklist;
  }


  getAccessionNumberBySuffixAndPrefix(prefix: string, suffix: string) {
    let response = this.api.get<StudyModel[]>(`${this.apiPATH}accessionByPrefixSuffix/${prefix}/${suffix}`);
    return response;
  }


  updateOrderStatus(studyModel: StudyModel) {
    //debugger;
    let response = this.api.put<boolean>(`${this.apiPATH}updateOrderStatus`, studyModel);
    return response;
  }

  resendLocalStudy(studyModel: StudyModel) {
    //debugger;
    let response = this.api.put<DicomParams>(`${this.apiPATH}resendLocalStudy`, studyModel);
    return response;
  }

  resendCloudStudy(dicomStudy: DicomParams) {
    try {
      let response = this.cloudApi.put<boolean>(`${this.apiPATH}resendCloudStudy`, dicomStudy);
      return response;
    } catch (error) {
      throw error;
    }
  }

  updateLocalStudyForReconcilation(studyModel: StudyModel) {
    //debugger;
    let response = this.api.put<StudyModel>(`${this.apiPATH}updateLocalStudyForReconcilation`, studyModel);
    return response;
  }

  updateCloudStudyForReconcilation(studyModel: StudyModel) {
    //debugger;
    let response =  this.cloudApi.put<boolean>(`${this.apiPATH}updateCloudStudyForReconcilation`, studyModel);
    return response;
  }


  cancelStudy(studyModel: StudyModel) {
    try {
      let response = this.api.put<boolean>(`${this.apiPATH}cancelStudyWorkList`, studyModel);
      return response;
    } catch (error) {
      throw error;
    }
  }

  cancelCloudStudy(studyModel: StudyModel) {
    try {
      let response = this.cloudApi.put<boolean>(`${this.apiPATH}cancelStudyWorkList`, studyModel);
      return response;
    } catch (error) {
      throw error;
    }
  }

  getExamResult(orderId: number, orgId: number) {
    let response = this.api.get<string>(`${this.apiPATH}getExamResult/${orderId}/${orgId}`);
    return response;
  }

  getOrderDate(accNo:string,orgId: number) {
    let response = this.api.get<string>(`${this.apiPATH}getOrderDate/${orgId}/${accNo}`);
    return response;
  }

}
