import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from '../api/api.service';
import { User } from "../../_models/user-models/user";
import { BehaviorSubject, Observable } from 'rxjs';
import { SiteInformation } from 'src/app/_models/site-information.model';
import { UserInfo } from 'src/app/_models/user-models/user-info.model';
import { SiteUserInformation } from 'src/app/_models/site-user-information.model';

@Injectable({
  providedIn: "root"
})
export class UserService {

  photoUrl = new BehaviorSubject<string>('');
  currentPhotoUrl = this.photoUrl.asObservable();
  private apiPATH = "User/";
  private uploadUrl = this.apiPATH +"/fileupload";
  private url: string;

  constructor(private router: Router, private api: ApiService) {}

  updateUserPassword(user:User):any {
    return this.api.put<User>(`${this.apiPATH}updateUserPassword`,user);
  }

   updateUser(user:User){
    return this.api.post<User>(`${this.apiPATH}updateUser/`,user);
  }

  approveUser(userId:number) {
    return this.api.put<boolean>(`${this.apiPATH}approveUser/${userId}`,null);
  }

  denyUser(user:User) {
    return this.api.post<boolean>(`${this.apiPATH}denyUser`,user);
  }

  getUserById(userId:number,isCloud: boolean):any {
    return this.api.get<User>(`${this.apiPATH}getUserById/${userId}/${isCloud}`);
  }

  getUserList() {
    return this.api.get<User[]>(`${this.apiPATH}users`);
  }

  getUserListByOrgId(orgId) {
    return this.api.get<User[]>(`${this.apiPATH}usersByOrgId/${orgId}`);
  }

  fileUpload(input:any) {
    return this.api.post<any>(`${this.apiPATH}fileupload`, input);
  }

  changeMemberPhoto(photoUrl: string) {
    this.photoUrl.next(photoUrl);
  }

  isExistEmail(email: string):Observable<boolean> {
    return this.api.get<boolean>(`${this.apiPATH}isExistingUserEmail/${email}`);
  }



  // save User information in HR_Emp Table
  addNewUser(user:User ) {
    try {
      return this.api.post<User>( `${this.apiPATH}AddNewUser`, user );
    } catch (error) {
      throw error;
    }
  }
  updateUserExperience(aUser:User)
  {
      return this.api.post<User>( `${this.apiPATH}updateUserExperience`, aUser);

  }


}
