import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';
import { AlertifyService } from 'src/app/_services/common/alertify.service';

@Component({
  selector: 'app-share-link',
  templateUrl: './share-link.component.html',
  styleUrls: ['./share-link.component.css']
})
export class ShareLinkComponent implements OnInit {

    routeShareId : string;
    redirectLink: string;
    message: string;

    constructor(private route : ActivatedRoute,
        private authService : AuthService,
        private alertify : AlertifyService) { }

    ngOnInit() {
        this.message = "";
        this.route.params.subscribe((params) => {
            if (params['linkId']) {
              this.routeShareId = params['linkId'];
              this.getRouteLink(this.routeShareId);
            }
          });
    }

    getRouteLink(routeShareId: string) {
        this.authService.getSharebleLinkById(routeShareId).subscribe(
            data =>{

                if(data){
                    this.redirectLink = data;
                    this.navigateToDicomViewer(this.redirectLink);
                }
                else{
                    this.alertify.warning("Link Expired");
                    this.message = "Link Expired"
                }

            }
        );

    }
    navigateToDicomViewer(redirectLink: string) {
        window.open(redirectLink,"_self");
    }

}
