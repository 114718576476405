import { Injectable } from '@angular/core';
import { ExternalRpt } from 'src/app/_models/reporting/externalRpt.model';
import { ApiService } from '../api/api.service';
import { CloudApiService } from '../api/cloudApi.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalService {

  private apiPATH = "External/";
constructor(private api: ApiService, private cloudApi: CloudApiService) { }

getExternalReport(externalRptParam:ExternalRpt){
  let response = this.api.post<string>(`${this.apiPATH}external-report`,externalRptParam)
  return response;
}

}
