import { Injectable } from "@angular/core";
import { shareReplay } from "rxjs/operators";
import { DicomParams } from "src/app/_models/dicom/dicom-params.model";
import { RisExam } from "src/app/_models/exam/ris-exam.model";
import { PatientStudyModel } from "src/app/_models/queue/patient-study.model";
import { StudyModel } from "src/app/_models/study.model";
import { ApiService } from "../api/api.service";
import { CloudApiService } from "../api/cloudApi.service";
import { debounceTime } from 'rxjs/operators';
import { ExamResult } from "src/app/_models/exam/exam-result.model";
import { RisExamResultTemplate } from "src/app/_modules/study/ris-template/ris-exam-result-template";
import { RptFindings } from "src/app/_models/reporting/rpt-findings";
import { RptFindingDtls } from "src/app/_models/reporting/rpt-finding-dtls";
import { SiteInformation } from "src/app/_models/site-information.model";
import { RptFindingDtlItem } from "src/app/_models/reporting/rpt-finding-dtl-item";
import { RisExamTemplate } from "src/app/_modules/study/ris-template/ris-exam-template";


@Injectable({
    providedIn : "root"
})
export class StudyImportService
{

    apiPath : string = "Import";
    constructor(private api : ApiService, private cloudApi : CloudApiService){

    }

    resendToRis(sudyModel : StudyModel){
        return this.api.post<boolean>(`${this.apiPath}/resendToRis`, sudyModel);
    }

    viewResultAndLock(orderId : number, userId : number, orgId : number, isDoubleClick: boolean){
        return this.api.get<ExamResult>(`${this.apiPath}/getResultAndLock/${orderId}/${userId}/${orgId}?isDoubleClick=${isDoubleClick}`);
    }

    getAllLockStudyModel(orgList: SiteInformation[]){
        return this.api.post<StudyModel[]>(`${this.apiPath}/getAllLockStudyModel/`, orgList);
    }

    unlockStudy(study : StudyModel){
        return this.api.post<boolean>(`${this.apiPath}/unlockStudy`, study);
    }


    unlockAndUpdate(examResult :  ExamResult){

        return this.api.post<boolean>(`${this.apiPath}/unlockAndUpdate`, examResult);
    }

    saveNewPatientStudyModel(patientModel :  PatientStudyModel)
    {
        return this.api.post<DicomParams>(`${this.apiPath}/saveNewPatientStudyModel`, patientModel);

    }


    saveNewPatientStudyModelToCloud(patientModel :  PatientStudyModel)
    {
        return this.cloudApi.post<PatientStudyModel>(`${this.apiPath}/saveNewPatientStudyModel`, patientModel);

    }

    getAllExamsByOrg(orgId :  number)
    {
        return this.api.get<RisExam[]>(`${this.apiPath}/getAllExamsByOrg/${orgId}`);

    }
    getNewQueueSerialByOrg(orgID : number){
        return this.api.get<number>(`${this.apiPath}/getNewQueueSearialByOrg/${orgID}`);
    }

    enableMwl(study : StudyModel){
        return this.api.put<boolean>(`${this.apiPath}/enableMwl/`, study);
    }

    getLocalImageFromFilePath(path : any){
        return this.api.post<any>(`${this.apiPath}/getLocalImageFromFilePath/`,path)
    }

    changePacsStatus(study : StudyModel){
        return this.api.post<boolean>(`${this.apiPath}/changePacsStatus/`,study)
    }

    addStudiestoPacsQueue(studyList : StudyModel[])
    {
        return this.api.post<boolean>(`${this.apiPath}/addStudiestoPacsQueue/`,studyList)

    }
    changeDefaultExam(defaultExam : RisExam)
    {
        return this.api.post<boolean>(`${this.apiPath}/changeDefaultExam/`, defaultExam);
    }

    syncfromCloud(orgId : number, busId : number){
        return this.api.get<boolean>(`${this.apiPath}/syncNewCloudStudies/${orgId}/${busId}`);
    }

    searchPatientBySSN(ssn : string){
        return this.api.get<StudyModel[]>(`${this.apiPath}/searchPatient/${ssn}`);
    }

    getResultTemplates(orgId:number, userId: number){
        return this.api.get<RisExamResultTemplate[]>(`${this.apiPath}/getResultTemplates/${orgId}/${userId}`);
    }

    getFindingsByOrgId(orgId: number) {
        return this.api.get<RptFindings[]>(`${this.apiPath}/getFindngs/${orgId}`);
    }

    getImpressionsByOrgId(orgId: number) {
        return this.api.get<RptFindings[]>(`${this.apiPath}/getImpression/${orgId}`);
    }

    getDefaultByOrgId(orgId: number) {
        return this.api.get<RptFindingDtlItem[]>(`${this.apiPath}/getDefaultByOrgId/${orgId}`);
    }

    getFindingDtlsByFindingId(findingId:number,orgId: number) {
        return this.api.get<RptFindingDtls[]>(`${this.apiPath}/getFindngDtl/${findingId}/${orgId}`);
    }

    getImpressionDtls(orgId: number) {
        return this.api.get<RptFindingDtls[]>(`${this.apiPath}/getImpressionDtls/${orgId}`);
    }

    saveToTemplate(dataTemp: RisExamTemplate){
        return this.api.post<boolean>(`${this.apiPath}/SaveToRisTemplate`, dataTemp);
    }

    getTemplateById(tempId:number, userId:number ,orgId: number) {
        return this.api.get<RisExamTemplate>(`${this.apiPath}/GetTemplateById/${tempId}/${userId}/${orgId}`);
    }

    deleteTemplateById(tempId:number, userId:number ,orgId: number) {
        return this.api.delete<boolean>(`${this.apiPath}/DeleteTemplateById/${tempId}/${userId}/${orgId}`);
    }
}
