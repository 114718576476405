import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { StudyModel } from '../../_models/study.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private apiPATH = "Auth/";
    constructor(private api:ApiService) { }


    login(model: any) {
        var userModel = { userEmail : model.Useremail, password:  model.Password, isCloud : model.IsCloud}
        return this.api.post<any>(`${this.apiPATH}login`,userModel);
    }

    logout(userId:number) {
        return this.api.get<any>(`${this.apiPATH}logout/${userId}`);
    }

    getSharebleLinkById(linkId : string)
    {
        return this.api.get<string>(`${this.apiPATH}getSharebleLinkById/${linkId}`);
    }

}
