import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { ModuleWithProviders, NgModule, InjectionToken} from '@angular/core';
import { LoginComponent } from './login/login.component';
import { SiteRegistrationComponent } from './_modules/app/site-registration/site-registration.component';
import { AppAiReportComponent } from './_modules/app/app.ai-report/app.ai-report.component';
import { SearchPatientComponent } from './search-patient/search-patient.component';
import { PatientRegistrationComponent } from './_modules/app/patient-registration/patient-registration.component';
import { ShareLinkComponent } from './_modules/app/share-link/share-link.component';
import { ExternalReportComponent } from './external-report/external-report.component';
import { DcmViewerComponent } from './_modules/app/dcm-viewer/dcm-viewer.component';



export const routes: Routes = [
  { path: '',      component: LoginComponent },

//   { path: 'register', component: RegisterComponent },
  //{ path: 'user', component: UserListComponent },
//   { path: 'verify/:username/:code', component: VerifyAccountComponent },
  // { path: 'study',  loadChildren: './_modules/study/study.module#StudyModule'},

  {path:'dec-viewer', component:DcmViewerComponent},  
  {path:'dec-viewer/:orgId/:accNo', component:DcmViewerComponent},
  {path:'external-report', component:ExternalReportComponent},
    { path: 'login', component: LoginComponent},
    { path: 'signUp', component: SiteRegistrationComponent},
    { path: 'report', component: AppAiReportComponent},
    {path:'tracking', component:SearchPatientComponent},
    {path:'register/:org/:bus', component:PatientRegistrationComponent},
    {path:'share-link/:linkId', component:ShareLinkComponent},
    


    { path: 'study',loadChildren: () => import('./_modules/study/study.module').then(mod => mod.StudyModule)},
    { path: '**',    component: LoginComponent },
];


@NgModule({
  providers: [
    {
        provide: new InjectionToken('externalUrlRedirectResolver'),
        useValue: (route: ActivatedRouteSnapshot) => {
            const externalUrl = route.paramMap.get('externalUrl');
            window.open(externalUrl, '_self');
        },
    },
],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
