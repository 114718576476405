import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

// import { appRoutes, AppMainRoutes } from './app.routes';



import { AppComponent} from './_modules/app/app.component/app.component';
import { AppMainComponent } from './_modules/app/app.main/app.main.component';


import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppLoginComponent } from './pages/app.login.component';
import { AppMenuComponent, AppSubMenuComponent} from './_modules/app/app.menu/app.menu.component';
import { AppTopbarComponent } from './_modules/app/app.topbar/app.topbar.component';
import { AppFooterComponent } from './_modules/app/app.footer/app.footer.component';
import { AppBreadcrumbComponent } from './_modules/app/app.breadcrumb/app.breadcrumb.component';
import { AppRightpanelComponent } from './_modules/app/app.rightpanel/app.rightpanel.component';
import { AppInlineProfileComponent } from './_modules/app/app.profile/app.profile.component';
import { StudyWorklistService } from './_services/worklist/study-worklist.service';
import { ApiService } from './_services/api/api.service';
import { LoginComponent } from './login/login.component';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { routes } from './app.routes';
import { ScrollPanelModule, ButtonModule, PanelModule, EditorModule, CardModule, CalendarModule, DropdownModule, RadioButtonModule } from 'primeng/primeng';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {JwtHelperService, JwtModule} from "@auth0/angular-jwt";
import {ConfirmationService} from "primeng/components/common/confirmationservice";
import {AlertifyService} from "./_services/common/alertify.service";
import {AppSessionState} from "./_services/common/app.service";
import {StoreModule} from "@ngrx/store";
import * as loginReducer from "./_store/reducers/login.reducer";
import { SiteRegistrationComponent } from './_modules/app/site-registration/site-registration.component';
import { InputMaskModule } from 'primeng/inputmask';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { DatePipe } from '@angular/common';
import { CloudApiService } from './_services/api/cloudApi.service';
import { AppAiReportComponent } from './_modules/app/app.ai-report/app.ai-report.component';
import { SearchPatientComponent } from './search-patient/search-patient.component';
import { TableModule } from 'primeng/table';
import { NgxBarcodeModule } from 'ngx-barcode';
import { PatientRegistrationComponent } from './_modules/app/patient-registration/patient-registration.component';
import { ShareLinkComponent } from './_modules/app/share-link/share-link.component';
import { ExternalReportComponent } from './external-report/external-report.component';
import { DcmViewerComponent } from './_modules/app/dcm-viewer/dcm-viewer.component';




const APP_PROVIDERS = [
    AlertifyService,
    AppSessionState,
    JwtHelperService,
    ConfirmationService,
    DatePipe
];
export function getAccessToken(){
    return localStorage.getItem('token');
}

@NgModule({
   imports: [
        BrowserModule,
        CalendarModule,
        DropdownModule,
        RadioButtonModule,
        BrowserAnimationsModule,
        FormsModule,
        TableModule,
        ReactiveFormsModule,
        ScrollPanelModule,
        InputMaskModule,
        ButtonModule,
        PanelModule,
        HttpClientModule,
        EditorModule,
        CardModule,
        AutoCompleteModule,
        NgxBarcodeModule,
        RouterModule.forRoot(routes, {
         useHash: true
         ,preloadingStrategy: PreloadAllModules
        }),
        JwtModule.forRoot({
           config: {
               tokenGetter: getAccessToken ,
               whitelistedDomains: ["localhost:44376", "localhost:8089"],
           }
        }),
       StoreModule.forRoot({ loginStore: loginReducer.reducer }),

   ],
   declarations: [	
      AppComponent,
      AppNotfoundComponent,
      AppErrorComponent,
      AppAccessdeniedComponent,
      AppLoginComponent,
      LoginComponent,
      SiteRegistrationComponent,
      AppAiReportComponent,
      SearchPatientComponent,
      PatientRegistrationComponent,
      ShareLinkComponent,
      ExternalReportComponent,
      DcmViewerComponent,
   ],
   providers: [
        APP_PROVIDERS,
        ApiService,
        CloudApiService,
        StudyWorklistService,
   ],
   exports: [
      InputMaskModule,
      ExternalReportComponent
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
